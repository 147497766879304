import React from "react";
import styled from "styled-components";

const AspectRatioContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${props => 100 / props.ratio}%;
`;

const AspectRatioContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const AspectRatio = ({ children, ratio, className }) => {
  return (
    <AspectRatioContainer ratio={ratio} className={className}>
      <AspectRatioContent>
        {children}
      </AspectRatioContent>
    </AspectRatioContainer>
  )
};

export default AspectRatio;